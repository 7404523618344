import { ref, child, get, set, update } from 'firebase/database';
import { db } from './Db';
import { DbCollections } from './../../src/constants';

const usersRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.UserCollection}`
);

/**
 * Adds a homeworkId to each student's homeworkIds array in Firebase.
 * @param {Array} students - Array of student objects with their IDs.
 * @param {string} homeworkId - The ID of the homework to add.
 */
async function addHomeworkIdToStudents(students, homeworkId) {
  try {
    if (!homeworkId) {
      console.error("Homework ID is undefined or null. Skipping addition.");
      return;
    }

    await Promise.all(students.map(async (student) => {
      if (!student.id) {
        console.warn(`Skipping student with undefined ID:`, student);
        return;
      }

      const studentHomeworkIdsPath = child(usersRef, `${student.id}/homeworkIds`);
      const studentHomeworkIdsSnapshot = await get(studentHomeworkIdsPath);

      let homeworkIds;

      // Check if homeworkIds key exists, initialize if not
      if (!studentHomeworkIdsSnapshot.exists()) {
        // If homeworkIds doesn't exist, create it as an empty array
        homeworkIds = [];
      } else {
        // If it exists, retrieve its current value
        homeworkIds = studentHomeworkIdsSnapshot.val();
      }

      // Only add the homeworkId if it doesn't already exist in the array
      if (!homeworkIds.includes(homeworkId)) {
        homeworkIds.push(homeworkId);
        await set(studentHomeworkIdsPath, homeworkIds);
      }
    }));

    console.log("Homework ID added to each selected student's homeworkIds successfully.");
  } catch (error) {
    console.error("Error adding homework ID to students:", error);
  }
}

export default addHomeworkIdToStudents;
