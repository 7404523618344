import {
  ref,
  child,
  get,
  query,
  orderByChild,
  remove,
  set,
  update,
  equalTo
} from 'firebase/database';
import { db } from './Db';
import { DbCollections } from './../../src/constants';
import moment from 'moment';
import CurriculumService from './CurriculumService';

const usersRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.UserCollection}`
);
const homeworksRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.HomeworkCollection}`
);
const subjectsRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.SubjectCollection}`
);

const curriculumsRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.CurriculumCollection}`
);

const marksRef = ref(
  db,
  `${DbCollections.DbName}/${DbCollections.MarksCollection}`
);

const HomeworkService = {
  get: id => {
    const query = child(homeworksRef, `${id}`);

    return new Promise((resolve, reject) => {
      get(query)
        .then(snapshot => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            console.warn(`User with id (${id}) was not found`);
            resolve(null);
          }
        })
        .catch(error => {
          console.error(`Error retrieving a user by id (${id})`, error);
          reject({
            source: 'GetUserById get catch',
            error: error,
            message: error?.message
          });
        });
    });
  },
  list: () => {
    try {
      let homeworksQuery = query(homeworksRef, orderByChild('id'));
      return new Promise((resolve, reject) => {
        return get(homeworksQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list homework get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('homework list error', error);
        reject({
          source: 'homework list catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  delete: id => {
    try {
      const homeworkPath = child(homeworksRef, `${id}`);
      return new Promise((resolve, reject) => {
        return remove(homeworkPath)
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            reject({
              source: 'delete homework remove catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        reject({
          source: 'delete homework catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  listSubjects: () => {
    try {
      let subjectsQuery = query(subjectsRef, orderByChild('id'));
      return new Promise((resolve, reject) => {
        return get(subjectsQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list subjects get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('subjects list error', error);
        reject({
          source: 'subjects list catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  listCurriculums: () => {
    try {
      let curriculumsQuery = query(curriculumsRef, orderByChild('id'));
      return new Promise((resolve, reject) => {
        return get(curriculumsQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              items.push(childSnapshot.val());
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list curriculums get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('curriculums list error', error);
        reject({
          source: 'curriculums list catch',
          error: error,
          message: error?.message
        });
      });
    }
  },
  save: async (data, user) => {
    try {
      let id = data?.id;
      if (!id) {
        const uuid = Date.now().toString();
        id = uuid.slice(uuid.length - 3, uuid.length);
      }

      let curriculumId; // Declare curriculumId here

      // Assuming 'user' object has an 'id' property
      // Adjust the path to include the specific user's ID
      const curriculumIdPath = `${DbCollections.DbName}/${DbCollections.UserCollection}/${user.id}/defaultCurriculumId`;

      try {
        // Use await to fetch curriculumId and assign it to the variable
        curriculumId = await CurriculumService.fetchCurriculumIdByPath(curriculumIdPath);
        console.log("Fetched curriculum ID: ", curriculumId);
      } catch (error) {
        console.error("Error fetching curriculum ID: ", error);
        throw error; // Or handle this error appropriately
      }

      const homeworkPath = child(homeworksRef, `${id}`);
      // Ensure curriculumId is accessible here and included in your data
      let fullData = {
        ...data,
        id,
        curriculumId, // Use curriculumId here
        // Additional data fields...
      };

      if (!data?.id) {
        fullData['createdAt'] = moment().format('MM/DD/yyyy HH:MM');
        fullData['createdBy'] = user?.id;
        await set(homeworkPath, fullData);
      } else {
        fullData['updatedAt'] = moment().format('MM/DD/yyyy HH:MM');
        fullData['updatedBy'] = user?.id;
        await update(homeworkPath, fullData);
      }

      console.log("Homework saved successfully.");
      return id; // Return the homework ID after saving
    } catch (error) {
      console.error("Error saving homework:", error);
      throw error;
    }
  },

  listMarks: studentId => {
    try {
      let marksQuery = null;
      if (!!studentId) {
        marksQuery = query(
          marksRef,
          equalTo(studentId),
          orderByChild('studentId')
        );
      } else {
        marksQuery = query(marksRef);
      }
      return new Promise((resolve, reject) => {
        return get(marksQuery)
          .then(snapshot => {
            const items = [];
            snapshot.forEach(childSnapshot => {
              if (!studentId || studentId === childSnapshot.val()?.studentId) {
                items.push(childSnapshot.val());
              }
            });
            resolve(items);
          })
          .catch(error => {
            reject({
              source: 'list marks get catch',
              error: error,
              message: error?.message
            });
          });
      });
    } catch (error) {
      return new Promise((_, reject) => {
        console.log('marks list error', error);
        reject({
          source: 'marks list catch',
          error: error,
          message: error?.message
        });
      });
    }
  }
};

export default HomeworkService;
